<template>
    <div class="container py-5">
        <h1>{{$t('invalidSignatureSession.heading')}}</h1>
        <p>{{$t('invalidSignatureSession.message')}}</p>
    </div>
</template>

<script>
export default {
    name: 'InvalidSession'
}
</script>
